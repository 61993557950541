import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { Media } from '@dayancloud/common/theme'

const MapContainer = styled.div`
  width: ${(p) => p.width};
  ${Media.lessThan(Media.small)} {
    width: ${(p) => p.widthSMDown};
  }
`

const BmapContainer = styled.div`
  height: ${(p) => p.height};
  ${Media.lessThan(Media.small)} {
    height: ${(p) => p.heightSMDown};
  }
`

//标注点数组
const markerObj = {
  title: '深圳市瑞云科技股份有限公司',
  content: '深圳市瑞云科技股份有限公司',
  point: '113.920892|22.519903',
  isOpen: 0,
  icon: {
    w: 23,
    h: 25,
    l: 46,
    t: 21,
    x: 9,
    lb: 12,
  },
}

class BDMap extends React.PureComponent {
  BMap = null
  componentDidMount() {
    if (typeof window === 'undefined') {
      return
    }
    //百度地图引用脚本的时候添加callback参数，当脚本加载完成后callback函数会被立刻调用
    window.initbdmap = () => {
      this.BMap = window.BMap
      this.createMap() //创建地图
      //this.setMapEvent() //设置地图事件
      //this.addMapControl() //向地图添加控件
      this.addMarker() //向地图中添加marker
    }
  }

  handleClickMap() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
      window.location.href = `http://api.map.baidu.com/geocoder?address=${markerObj.title}&output=html&src=www.renderbus.com`
    }
  }
  //创建地图函数：
  createMap() {
    var map = new this.BMap.Map('bmapContainer') //在百度地图容器中创建一个地图
    var point = new this.BMap.Point(113.920892, 22.519803) //定义一个中心点坐标
    map.centerAndZoom(point, 17) //设定地图的中心点和坐标并将地图显示在地图容器中
    this.map = map //将map变量存储在全局
    var marker = new this.BMap.Marker(point) // 创建标注
    map.addOverlay(marker) // 将标注添加到地图中
    //        marker.setAnimation(BMAP_ANIMATION_BOUNCE);
    map.addEventListener('click', this.handleClickMap)
  }

  //地图事件设置函数：
  setMapEvent() {
    this.map.enableDragging() //启用地图拖拽事件，默认启用(可不写)
    this.map.enableScrollWheelZoom() //启用地图滚轮放大缩小
    this.map.enableDoubleClickZoom() //启用鼠标双击放大，默认启用(可不写)
    this.map.enableKeyboard() //启用键盘上下左右键移动地图
  }

  //地图控件添加函数：
  addMapControl() {
    //向地图中添加缩放控件
    var ctrl_nav = new this.BMap.NavigationControl({
      anchor: window.BMAP_ANCHOR_TOP_LEFT,
      type: window.BMAP_NAVIGATION_CONTROL_ZOOM,
    })
    this.map.addControl(ctrl_nav)
    //向地图中添加缩略图控件
    var ctrl_ove = new this.BMap.OverviewMapControl({
      anchor: window.BMAP_ANCHOR_BOTTOM_RIGHT,
      isOpen: 0,
    })
    this.map.addControl(ctrl_ove)
    //向地图中添加比例尺控件
    var ctrl_sca = new this.BMap.ScaleControl({
      anchor: window.BMAP_ANCHOR_BOTTOM_LEFT,
    })
    this.map.addControl(ctrl_sca)
  }

  //创建marker
  addMarker() {
    var json = markerObj
    var p0 = json.point.split('|')[0]
    var p1 = json.point.split('|')[1]
    var point = new this.BMap.Point(p0, p1)
    var iconImg = this.createIcon(json.icon)
    var marker = new this.BMap.Marker(point, {
      icon: iconImg,
    })
    var label = new this.BMap.Label(json.title, {
      offset: new this.BMap.Size(json.icon.lb - json.icon.x + 10, -20),
    })
    marker.setLabel(label)
    this.map.addOverlay(marker)
    label.setStyle({
      borderColor: '#808080',
      color: '#333',
      cursor: 'pointer',
    })
    var _iw = this.createInfoWindow()
    var _marker = marker
    _marker.addEventListener('click', function () {
      this.openInfoWindow(_iw)
    })
    _iw.addEventListener('open', function () {
      _marker.getLabel().hide()
    })
    _iw.addEventListener('close', function () {
      _marker.getLabel().show()
    })
    label.addEventListener('click', function () {
      _marker.openInfoWindow(_iw)
    })
    if (!!json.isOpen) {
      label.hide()
      _marker.openInfoWindow(_iw)
    }
  }
  //创建InfoWindow
  createInfoWindow() {
    var json = markerObj
    var iw = new this.BMap.InfoWindow(
      `<b class='iw_poi_title' title='${json.title}'>${json.title}</b><div class='iw_poi_content'>${json.content}</div>`,
    )
    return iw
  }
  //创建一个Icon
  createIcon(json) {
    var icon = new this.BMap.Icon(
      'https://app.baidu.com/map/images/us_mk_icon.png',
      new this.BMap.Size(json.w, json.h),
      {
        imageOffset: new this.BMap.Size(-json.l, -json.t),
        infoWindowOffset: new this.BMap.Size(json.lb + 5, 1),
        offset: new this.BMap.Size(json.x, json.h),
      },
    )
    return icon
  }
  render() {
    let { width, height, widthSMDown, heightSMDown } = this.props
    return (
      <MapContainer widthSMDown={widthSMDown} width={width}>
        <Helmet>
          <script
            type='text/javascript'
            src='https://api.map.baidu.com/api?key=&v=2.0&services=true&callback=initbdmap'
            async='true'
          ></script>
        </Helmet>
        <BmapContainer
          id='bmapContainer'
          height={height}
          heightSMDown={heightSMDown}
        ></BmapContainer>
      </MapContainer>
    )
  }
}

BDMap.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  widthSMDown: PropTypes.string,
}

BDMap.defaultProps = {
  width: '100%',
  height: '300px',
  widthSMDown: '100%',
}

export default BDMap
