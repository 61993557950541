import styled from 'styled-components'
import { Wrapper, H1, Flex } from '@dayancloud/common/components'
import { color, Media, typography } from '@dayancloud/common/theme'

export const BannerContainer = styled.div`
  position: relative;
  height: 670px;
  z-index: 0;
  text-align: center;
  background: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 60px;
    font-weight: 400;
    color: #fff;
  }
  ${Media.lessThan(Media.small)} {
    height: 245px;
    p {
      font-size: ${typography.h2};
    }
  }
`
export const ContentContainer = styled.div`
  text-align: center;
  background: #fff;
  padding: 100px 0;
  ${H1} {
    font-size: 60px;
    text-shadow: rgba(0, 0, 0, 0.75) 3px 0 3px;
  }
  ${Media.lessThan(Media.small)} {
    padding: 30px 0;
    ${H1} {
      font-size: ${typography.h2};
    }
  }
`
export const Desc = styled(Wrapper)`
  text-align: left;
  color: ${color.text};
  font-size: 15px;
  span,
  a {
    color: ${color.primary};
  }
`
export const ContactInfoContainer = styled(Wrapper)`
  padding: 90px 40px 104px;
  ${Media.lessThan(Media.small)} {
    padding: 40px 0 48px;
    ${H1} {
      font-size: ${typography.h2};
    }
  }
`
export const ContactInfoWrap = styled(Flex)`
  justify-content: space-between;
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    padding: 0 15px;
  }
`
export const ContactCard = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  width: 540px;
  height: 200px;
  border-radius: 20px;
  padding: 40px 30px;
  color: ${color.text};
  font-size: ${typography.h4};
  img {
    width: 120px;
    height: 120px;
    margin-right: 30px;
  }
  .title {
    font-size: ${typography.h3};
    font-weight: 500;
    color: #000000;
    margin-bottom: 17px;
  }
  ${Media.lessThan(Media.small)} {
    width: 100%;
    height: auto;
    padding: 20px;
    margin-bottom: 10px;
    img {
      width: 80px;
      height: 80px;
      margin-right: 10px;
    }
    .title {
      font-size: ${typography.h4};
      margin-bottom: 9px;
    }
    .subtitle {
      font-size: 15px;
    }
    ${H1} {
      font-size: ${typography.h2};
    }
  }
`
export const MapContainer = styled.div`
  background: #fff;
  padding: 87px 30px;
  ${H1} {
    font-size: 60px;
  }
  ${Media.lessThan(Media.small)} {
    padding: 39px 0;
    ${H1} {
      font-size: ${typography.h2};
    }
  }
`
export const MapContent = styled(Wrapper)`
  display: flex;
  justify-content: flex-start;
  padding: 0 30px;
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    padding: 0 37px 12px;
    ${H1} {
      font-size: ${typography.h2};
    }
  }
`
export const ContactInfoDetail = styled.div`
  margin-top: 68px;
  font-weight: 400;
  text-align: left;
  margin-left: 60px;
  .title {
    color: #000000;
    font-size: ${typography.h2};
    margin-bottom: 28px;
  }
  .info-item {
    font-size: ${typography.h4};
    color: ${color.text};
    line-height: 36px;
  }
  ${Media.lessThan(Media.small)} {
    margin: 30px 0 0 0;
    .title {
      font-size: ${typography.h4};
      margin-bottom: 10px;
    }
    .info-item {
      font-size: ${typography.text};
      line-height: 1.5;
    }
  }
`
