import React from 'react'
import { graphql } from 'gatsby'
import { SEO, Billboard, SectionTitle, Layout } from '@dayancloud/common/components'
import {
  BannerContainer,
  ContentContainer,
  Desc,
  ContactInfoContainer,
  ContactCard,
  ContactInfoWrap,
  MapContainer,
  MapContent,
  ContactInfoDetail,
} from './about.atom'
import IconBusinessImg from '../images/about/icon-business.png'
import IconZixunImg from '../images/about/icon-zixun.png'
import BDMap from '../molecules/about/bd-map'

class About extends React.PureComponent {
  render() {
    const {
      data,
      location,
      pageContext: { topFiveNews },
    } = this.props
    return (
      <Layout location={location} topFiveNews={topFiveNews}>
        <SEO
          title='大雁云实景三维重建云服务-深圳市瑞云科技股份有限公司'
          keywords='大雁云实景三维重建平台,实景三维建模解决方案,无人机倾斜摄影建模'
          description='大雁云实景三维重建平台是瑞云科技推出的一款用户可自助便捷、快速将无人机倾斜摄影二维图片转为三维数据模型的工具，大雁云旨在为智慧城市、工程建设、乡村规划、应急测绘等行业赋能,打造云服务生态闭环。'
          ogUrl='https://www.dayancloud.com/about.html'
          ogType='website'
        />
        <BannerContainer>
          <p>顷刻之间，形聚九野</p>
          <Billboard fluid={data.banner.childImageSharp.fluid} />
        </BannerContainer>
        <ContentContainer>
          <SectionTitle marginBottom={58}>关于我们</SectionTitle>
          <Desc>
            <div>
              深圳市瑞云科技股份有限公司(简称“瑞云科技”)是一家专注为视觉行业提供云计算服务的公司，拥有单集群10000节点的大规模算力池，专业的TD服务团队以及一支在并行计算、大规模集群管理与调度、高性能
              I/O系统等专业方向经验丰富的系统开发团队。瑞云科技的投资方包括丝路视觉，阿里巴巴，君联资本，光远资本，中信证券等。
            </div>
            <br />
            <div>
              大雁云实景三维重建服务是瑞云科技推出的一款为用户提供高效、安全、稳定、流畅、可靠的实景云端重建模型平台，在海量机器秒级调度的基础之上，提供自助式实景三维重建云服务，操作便捷，只需一键上传即可将二维图片转化为三维数据模型。大雁云旨在为智慧城市、工程建设管控、乡村规划、应急测绘等行业赋能,打造云服务生态闭环。
            </div>
            <br />
            <div>大雁云实景三维重建平台，在顷刻之间，让形聚九野。</div>
            <br />
            <a href='https://www.rayvision.com/about.html' target='_blank' rel='noreferrer'>
              关于瑞云科技
            </a>
          </Desc>
        </ContentContainer>
        <ContactInfoContainer>
          <SectionTitle marginBottom={68}>合作咨询</SectionTitle>
          <ContactInfoWrap>
            <ContactCard>
              <img src={IconBusinessImg} alt='' />
              <div>
                <div className='title'>商务合作</div>
                <div className='subtitle'>服务热线：+86 189 0245 2974 </div>
              </div>
            </ContactCard>
            <ContactCard>
              <img src={IconZixunImg} alt='' />
              <div>
                <div className='title'>业务咨询</div>
                <div className='subtitle'>业务邮箱： liumingyan@rayvision.com</div>
              </div>
            </ContactCard>
          </ContactInfoWrap>
        </ContactInfoContainer>
        <MapContainer>
          <SectionTitle marginBottom={50} marginBottomMobile={21}>
            联系我们
          </SectionTitle>
          <MapContent>
            <BDMap width='600px' height='346px' heightSMDown='180px' widthSMDown='100%' />
            <ContactInfoDetail>
              <div className='title'>深圳市瑞云科技股份有限公司</div>
              <div className='info-item '>地址：深圳市南山区商华路2号阳光科创中心B座17层</div>
              <div className='info-item '>咨询热线：+86 189 0245 2974</div>
              <div className='info-item '>咨询邮箱：liumingyan@rayvision.com</div>
            </ContactInfoDetail>
          </MapContent>
        </MapContainer>
      </Layout>
    )
  }
}

export default About

export const query = graphql`
  query {
    banner: file(relativePath: { regex: "/about/banner.png/" }) {
      ...fluidImage
    }
  }
`
